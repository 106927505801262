import URL from '@/finsight/Router/urlConstants';

export const ABS_PRODUCT_ID = 'df146e90-5edc-4182-9328-98cee1bd403b';
export const HYC_PRODUCT_ID = 'd74c0c41-aa65-4870-92c4-d9e6eb377802';
export const IGC_PRODUCT_ID = '837f88ba-b7cc-4f94-ad79-898d2d28148f';
export const LM_PRODUCT_ID = 'e2e0fb50-4a15-4503-853a-4bcbf94361d7';

export enum ProductAbbreviations {
  ABS = 'ABS',
  HYC = 'HYC',
  IGC = 'IGC',
  LM = 'LM',
}

export const ProductAbbreviationById = {
  [ABS_PRODUCT_ID]: ProductAbbreviations.ABS,
  [HYC_PRODUCT_ID]: ProductAbbreviations.HYC,
  [IGC_PRODUCT_ID]: ProductAbbreviations.IGC,
};

export const ProductIdByAbbreviation = {
  [ProductAbbreviations.ABS]: ABS_PRODUCT_ID,
  [ProductAbbreviations.HYC]: HYC_PRODUCT_ID,
  [ProductAbbreviations.IGC]: IGC_PRODUCT_ID,
  [ProductAbbreviations.LM]: LM_PRODUCT_ID,
};

class Product {
  static isAbsProductId(productId) {
    return productId === ABS_PRODUCT_ID;
  }

  static isLMProductId(productId) {
    return productId === LM_PRODUCT_ID;
  }

  static isAbsProductOnly(productIds) {
    return productIds.length === 1 && productIds.includes(ABS_PRODUCT_ID);
  }

  /**
   * @param {Array<Object>} products collection
   * @param {Array<String>|String} abbreviations
   * @param {Boolean} idsOnly
   */
  static getByAbbreviations(products, abbreviations, idsOnly) {
    let results = products.filter((product) => abbreviations.includes(product.abbreviation));

    return idsOnly
      ? results.map((result) => result.id)
      : results;
  }

  /**
   * @return {string}
   */
  static getDefault() {
    return ProductAbbreviations.ABS;
  }

  /**
   * @param {String} pathname
   * @return {string}
   */
  static getDefaultProductByPath(pathname) {
    if (pathname === URL.MARKET_OVERVIEW_HYC) {
      return ProductAbbreviations.HYC;
    }
    if (pathname === URL.MARKET_OVERVIEW_IGC) {
      return ProductAbbreviations.IGC;
    }
    return ProductAbbreviations.ABS;
  }
}

export default Product;
